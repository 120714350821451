.objects {
    margin-top: 20px;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap:20px;


    @media screen and (min-width:400px) and (max-width:1200px) {
        grid-template-columns: repeat(2, 1fr);;
    }

    @media screen and (max-width:400px) {
        grid-template-columns: 100%;
    }

    
}

.bonusBadge {
    margin: 10px;
    display: inline-block;
    background-color: var(--primary500);
    border-radius: 5px;
    padding: 5px;
    color: #fff;
}

.object {
    display: flex;
    flex-direction: column;

    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    word-break: break-all;

    cursor: pointer;

    &__img {
        height: 200px;

        border-radius: 10px 10px 0 0;
    }

    &__content {
        height: 215px;
        padding: 15px;
        padding-bottom: 20px;
        box-sizing: border-box;
        
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__company {
        margin-bottom: 3px;

        color: var(--primary500);
        font-size: 12px;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    &__name {
        font-size: 16px;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
    }

    &__description {
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        gap:2px;

        font-size: 14px;
        color: var(--gray800);

        &_line {
            display: flex;
            gap: 2px 5px;

            flex-wrap: wrap;

        }

        &_item {

        }
    }

    &__price {
        margin-right: 10px;
        font-size: 16px;
    }

    &__buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 5px 0;
    }

    &__link {
        display: flex;
        margin-right: 20px;
        color: var(--blue500);
        text-decoration: none;
        cursor: pointer;
    }

    &__linkText {
        margin-left: 5px;
        text-decoration: none;
        color: var(--blue500);
    }

    &__type {
        margin: 10px 0 0 10px;
        padding: 5px 10px;
        display: inline-block;
        
        border-radius: 5px;
        background: var(--primary500);
        color: #fff;
    }
}

.filter {
    &Error{
        margin-top: 40px;

        text-align: center;
        width: 100%;
        & svg {
            text-align: center;
        }

        &__header {
            margin-top: 10px;
            font-weight: bold;
            text-align: center;
            font-size: 20px;
        }
        &__text {
            margin-top: 5px;

            text-align: center;
            font-size: 20px;
        }

        &__link {
            color: var(--blue500);
            text-decoration: none;
            cursor: pointer;
        }
    }
}