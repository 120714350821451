.button_m {
    height: 50px;
    padding: 0 20px;
    
    box-sizing: border-box;
    display: inline-block;
   
    background: var(--primary500);
    border-radius: 10px;
    outline: none;
    border: none;

    font-size: 20px;
    font-weight: 400;
    font-family: "Museo Sans";
    text-align: center;
    text-decoration: none;
    line-height: 50px;
    color: #fff; 
}