.input {
    padding-left: 20px;
    height: 50px;
    
    box-sizing: border-box;
   
    background: rgba(230, 234, 242, 0.5);
    border-radius: 10px;
    outline: none;
    border: none;

    font-size: 20px;
    line-height: 50px;
    color: #000; 
}

.button {
    height: 50px;
    
    box-sizing: border-box;
   
    background: var(--primary500);
    border-radius: 10px;
    outline: none;
    border: none;

    font-size: 20px;
    font-weight: 400;
    font-family: "Museo Sans";
    text-align: center;
    line-height: 50px;
    color: #fff; 
}

.link {
    color: var(--blue500);
    text-decoration: none;
}