.filters {
    margin-top: 78px;
    padding: 10px 0;
    background: #fff;

    @media screen and (max-width:400px) {
        height: 100%;
        margin-top: 0;
        padding: 10px;
        position: fixed;
        top: 0;
        transform: translateX(-500px);
        transition: transform 0.5s;

        &_show {
            transform: translateX(0px);
        }
    }

    
}

.container {
    position: sticky;
    top: 20px;

    display: flex;
    flex-direction: column;
    gap:10px;


    @media screen and (max-width:400px) {
        gap: 10px;
    }
}

.ValueContainer {
    flex-wrap: nowrap;
}

