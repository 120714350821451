@font-face {
    font-family: "Museo Sans";
    src: url("fonts/MuseoSansCyrl-500.woff");
    font-weight: 400;
  }
  
  @font-face {
    font-family: "Museo Sans";
    src: url("fonts/MuseoSansCyrl-700.woff");
    font-weight: 700;
  }

html,
body, 
* {
  padding: 0;
  margin: 0;
}

  .App {
    font-family: "Museo Sans";
  }

  .contentWrapper {
    margin-left: 100px;
    margin-right: 20px;

    display: grid;
    grid-template-columns: 1fr;

    @media screen and (max-width:1200px) and (min-width:400px){
        grid-template-columns: 1fr;
    }

    @media screen and (max-width:400px) {
      margin-right: 0px;
      margin-left: 0;
      grid-template-columns: 100%;
    }
  }