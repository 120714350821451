.mainMenu {
    padding-top: 30px;
    box-sizing: border-box;
    width: 100px;
    height: 100vh;

    position: fixed;
    left: 0;
    top: 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;

    background: #31323C;

    @media screen and (max-width:400px) {
        display: none;
    }
}

.logo {
    margin-bottom: 30px;
    width: 34px;
    height: 34px;

    text-align: center;
}

.menuItem {
    display: block;
    margin-bottom: 20px;

    text-align: center;
    color: rgba(255, 255, 255, 0.8);
        
    font-size: 12px;
    line-height: 14px;
    text-decoration: none;

    &_active {
        color: #AB90FF;
    }
}
