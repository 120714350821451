.inputGroup {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.rangeInput {
    display: flex;
    gap: 10px;
}

.inputContainer {
    position: relative;
    flex: 1;

}

.input {
    width: 100%;
    min-height: 38px;
    border-radius: 4px;
    font-size: 16px;
    font-family: inherit;
    background: #F3F4F8;
    padding: 2px 8px 2px 40px;
    box-sizing: border-box;
    border: 1px solid hsl(0, 0%, 80%);

    &:focus {
        border-color: rgba(0,0,0,0);
        outline: 2px solid var(--blue500);
    }
}

.inputLabel {
    position: absolute;
    left: 10px;
    margin-top: 3px;
    line-height: 32px;
}

.inputDimension {
    position: absolute;
    right: 10px;
    margin-top: 3px;
    line-height: 32px;
}