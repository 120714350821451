.benefits {
      margin-top: 100px;

      padding: 30px 50px 40px 30px;

      background: #F2EFFB;
      border-radius: 10px;
}

.heading {
      margin-bottom: 25px;

      font-size: 24px;
      font-weight: bold;
}

.items {   
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap:20px;

      @media screen and (max-width:400px) {
            grid-template-columns: 100%;
            gap:50px;
      }
}

.item {
      display: flex;
      align-items: center;

      @media screen and (max-width:400px) {
            flex-direction: column;
            gap: 20px;
            text-align: center;
      }
}

.name {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 5px;
}

.text {
      font-size: 18px;
}

.icon {
      margin-right: 20px;

      display: flex;
      justify-content: center;
      align-items: center;

      flex-basis: 100px;
      flex-shrink: 0;
      height: 100px;
      width: 100px;

      border-radius: 50px;
      background: #E8E2FC;
}